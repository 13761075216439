import type { ImplementedCopilotFeatures } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import { trace } from 'owa-trace';
export function hasCopilotEnabledFeatures(mailboxInfo: MailboxInfo): boolean {
    const store = copilotSettingsStore(mailboxInfo);
    let result: boolean = false;
    if (store.isEnabled && store.featureInfo.size > 0) {
        const enabledFeatures: ImplementedCopilotFeatures[] = [];
        [...store.featureInfo.entries()].forEach(([featureName, featureInfo]) => {
            if (featureInfo.isEnabled) {
                enabledFeatures.push(featureName);
            }
        });
        result = enabledFeatures.length > 0;
    }

    trace.info(`hasCopilotEnabledFeatures: ${result}, mailbox: ${mailboxInfo.mailboxSmtpAddress}`);
    return result;
}
