import { isFeatureEnabled } from 'owa-feature-flags';
import {
    OwsOptionsFeatureType,
    EditOptionsCommand,
    MonarchKeyboardShortcutsMode,
    PinnablePane,
    type AnimationPreference,
} from 'owa-outlook-service-option-store';
import type {
    AdsAggregateOptions,
    TranslationOptions,
    CalendarHelpOptions,
    CalendarSurfaceOptions,
    GdprAdsV3Options,
    ProofingOptions,
    ActiveProxyAddressOptions,
    LinkedInViewProfileOptions,
    EventCaptureOptions,
    ExternalImagesOptions,
    CalendarSurfaceAddinsOptions,
    ComposeAssistanceOptions,
    ConfettiOptions,
    DiverseEmojisOptions,
    SurfaceActionsOptions,
    SmartSuggestionsOptions,
    SkypeNotificationOptions,
    OwsOptionsBase,
    SxSOptions,
    ReadingPaneConversationOptions,
    ListViewColumnHeadersOptions,
    MailLayoutOptions,
    SearchOptions,
    LgpdAdsOptions,
    MicrosoftChoiceOptions,
    StartUpOptions,
    KeyboardShortcutsOptions,
    EmailHeaderContentOptions,
    PinnedPaneOptions,
    EUEmailAdsOptions,
    OfficeNeutralThemeOptions,
    IsBusinessConsumerOptions,
    EditorAutoFormatOptions,
    PremiumStatusInPrimarySettingsOptions,
    UseSystemDarkModeOptions,
    ComposeFormInPopOutOptions,
    TeamsEmbedChatOptions,
    BoostOptions,
    AppBarDisplayModeOptions,
    DictationOptions,
    CopilotChatFREOptions,
    MonthlyUpdatesOptions,
} from 'owa-outlook-service-option-store';

export type DefaultOptionsType = {
    [key: number]: Partial<OwsOptionsBase>;
};

/**
 * These are the default options to use when we have successfully retrieved the user's options,
 * and they have not yet set any of the values for a given feature's options.
 *
 * Options are loaded into the store from the sources. First, some options are pulled down
 * as a part of session data. Store values are set up for these options in `userConfigurationSetMutator`.
 * All options are pulled down later in the boot flow when the `loadOptions` request is made. It is after this
 * request is made that these default options are applied.
 *
 * These are distinct from the "fail-safe" options specified in the default store value in store.ts
 */
export default function getDefaultOptions(): DefaultOptionsType {
    const fluidOption = {} as OwsOptionsBase;
    const isRelocateHoverActionsEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');
    return {
        [OwsOptionsFeatureType.AdsAggregate]: {
            feature: OwsOptionsFeatureType.AdsAggregate,
            nativeAdsSeenRunningSum: 0,
            nativeAdsClickedRunningSum: 0,
            nativeAdsDeletedRunningSum: 0,
            nativeCPMRunningSum: 0,
            nativeWinCPMRunningSum: 0,
            wasAdSeenInLastSession: false,
            startOfAdFreeTrial: '',
            miniNativeAdsSeenRunningSum: 0,
            miniNativeAdsClickedRunningSum: 0,
            miniNativeAdsDeletedRunningSum: 0,
            miniNativeCPMRunningSum: 0,
            miniNativeWinCPMRunningSum: 0,
            miniWasAdSeenInLastSession: false,
        } as AdsAggregateOptions,
        [OwsOptionsFeatureType.SmartSuggestions]: {
            smartSuggestionsEnabled: true,
            browserLocationEnabled: false,
        } as SmartSuggestionsOptions,
        [OwsOptionsFeatureType.DiverseEmojis]: {
            diverseEmojisSelectedSkinTone: '',
        } as DiverseEmojisOptions,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2352 (85,49): Conversion of type '{ readSurfaceActions: string[]; readSurfaceAddins: never[]; composeSurfaceActions: ("AddAttachment" | "AddClassicAttachment" | "InsertLink" | "AddInlineImage" | "AddEmoji" | "ToggleRibbon" | "QuickUse" | "ProofingOptions" | "FluidHeroButton")[]; composeSurfaceAddins: never[]; }' to type 'SurfaceActionsOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        // @ts-expect-error
        [OwsOptionsFeatureType.SurfaceActions]: {
            readSurfaceActions: ['Reply', 'ReplyAll', 'Forward'],
            readSurfaceAddins: [],
            composeSurfaceActions: [
                'AddAttachment',
                'AddClassicAttachment',
                'InsertLink',
                'AddInlineImage',
                'AddEmoji',
                'ProofingOptions',
                'QuickUse',
                'ToggleRibbon',
                'FluidHeroButton',
            ],
            composeSurfaceAddins: [],
        } as SurfaceActionsOptions,
        [OwsOptionsFeatureType.SkypeNotifications]: {
            skypeMessageNotification: 1, // Toast and Sound
            skypeCallingNotification: 1, // Toast and Sound
        } as SkypeNotificationOptions,
        [OwsOptionsFeatureType.Confetti]: {
            confettiEnabled: true,
        } as ConfettiOptions,
        [OwsOptionsFeatureType.Boost]: {
            boostEnabled: true,
            keywordsEnabled: true,
            mailOpenBehavior: 0,
        } as BoostOptions,
        [OwsOptionsFeatureType.ComposeAssistance]: {
            composeAssistanceEnabled: true,
        } as ComposeAssistanceOptions,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2352 (117,56): Conversion of type '{ calendarSurfaceAddins: never[]; }' to type 'CalendarSurfaceAddinsOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        // @ts-expect-error
        [OwsOptionsFeatureType.CalendarSurfaceAddins]: {
            calendarSurfaceAddins: [],
        } as CalendarSurfaceAddinsOptions,
        [OwsOptionsFeatureType.ExternalImages]: {
            externalImagesSelectedOption: 0, //Display via image proxy
        } as ExternalImagesOptions,
        [OwsOptionsFeatureType.EventCapture]: {
            autoCollectionEnabled: false,
        } as EventCaptureOptions,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2352 (129,46): Conversion of type '{ translationMode: number; targetLanguage: string; excludedLanguages: never[]; }' to type 'TranslationOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        // @ts-expect-error
        [OwsOptionsFeatureType.Translation]: {
            translationMode: 0,
            targetLanguage: '',
            excludedLanguages: [],
        } as TranslationOptions,
        [OwsOptionsFeatureType.CalendarSurfaceOptions]: {
            lastKnownRoamingTimeZone: undefined,
            roamingTimeZoneNotificationsIsDisabled: false,
            allDayWellHeight: 0,
            numDaysInDayRange: 1,
            timeScaleSetting: 30,
            isDynamicColumnWidthEnabled: true,
        } as CalendarSurfaceOptions,
        [OwsOptionsFeatureType.CalendarHelp]: {
            calendarHelpEnabled: false,
        } as CalendarHelpOptions,
        [OwsOptionsFeatureType.LinkedInViewProfile]: {
            dismissed: false,
        } as LinkedInViewProfileOptions,
        // Bohemia is deprecated, but it's not safe to remove it from the code because each feature type is enumarated and might be stored
        // keeping it with OwsOptionsBase
        [OwsOptionsFeatureType.Bohemia]: fluidOption,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2352 (165,44): Conversion of type '{ feature: OwsOptionsFeatureType.GdprAdsV3; encodedTCString: null; allStoreAndAccessDevice: false; allowDevelopAndImproveProduct: false; allowPersonalisedAds: false; ... 12 more ...; selectedVendorId: never[]; }' to type 'GdprAdsV3Options' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        // @ts-expect-error
        [OwsOptionsFeatureType.GdprAdsV3]: {
            feature: OwsOptionsFeatureType.GdprAdsV3,
            encodedTCString: null,
            allStoreAndAccessDevice: false,
            allowDevelopAndImproveProduct: false,
            allowPersonalisedAds: false,
            allowPersonalisedAdsSelectBasicAd: false,
            allowPersonalisedAdsApplyMarketResearch: false,
            allowPersonalisedAdsMeasureContentPerformance: false,
            allowPersonalisedAdsSelectPersonalisedAds: false,
            allowPersonalisedAdsMeasureAdPerf: false,
            allowPersonalisedAdsSelectPersonalisedContent: false,
            allowPersonalisedAdsCreatePersonalisedContentProfile: false,
            allowPersonalisedAdsCreatePersonalisedAdsProfile: false,
            allowPreciseGeoDataAndIdentifyDevice: false,
            allowPreciseGeoDataAndIdentifyDeviceScanDevice: false,
            allowPreciseGeoDataAndIdentifyDeviceGeoData: false,
            disselectedVendorId: [],
            selectedVendorId: [],
        } as GdprAdsV3Options,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2352 (188,43): Conversion of type '{ feature: OwsOptionsFeatureType.Proofing; spellCheckEnabled: true; grammarEnabled: true; writingRefinementsEnabled: true; proofingLocale: undefined; overriddenOptions: undefined; isTonalFreExperienceEnabled: true; }' to type 'ProofingOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        // @ts-expect-error
        [OwsOptionsFeatureType.Proofing]: {
            feature: OwsOptionsFeatureType.Proofing,
            spellCheckEnabled: true,
            grammarEnabled: true,
            writingRefinementsEnabled: true,
            proofingLocale: undefined,
            overriddenOptions: undefined,
            isTonalFreExperienceEnabled: true,
            isEditorIndicatorEnabled: true,
            isToneEnabledInEditorIndicator: true,
            shouldShowIndicatorButton: false,
            areMxLanguagesSeeded: false,
            isLanguageAutoDetectEnabled: true,
        } as ProofingOptions,
        [OwsOptionsFeatureType.ActiveProxyAddress]: {
            feature: OwsOptionsFeatureType.ActiveProxyAddress,
            activeProxyAddresses: [],
        } as ActiveProxyAddressOptions,
        [OwsOptionsFeatureType.SxS]: {
            feature: OwsOptionsFeatureType.SxS,
            hideReadingPane: false,
            defaultEditCommand: EditOptionsCommand.EditInBrowser,
        } as SxSOptions,
        [OwsOptionsFeatureType.ReadingPaneConversation]: {
            feature: OwsOptionsFeatureType.ReadingPaneConversation,
            conversationEnabled: true,
            conversationEnabledNativeHost: false,
        } as ReadingPaneConversationOptions,
        [OwsOptionsFeatureType.ListViewColumnHeaders]: {
            feature: OwsOptionsFeatureType.ListViewColumnHeaders,
            // Received column is now fixed width and the subject column is now twice the size of the sender column
            // total width of the list view with new column widths is still approximately 1482px
            senderColumnWidth: isRelocateHoverActionsEnabled ? 192 : 332,
            subjectColumnWidth: isRelocateHoverActionsEnabled ? 1228 : 1000,
            receivedColumnWidth: isRelocateHoverActionsEnabled ? 80 : 150,
        } as ListViewColumnHeadersOptions,
        [OwsOptionsFeatureType.MailLayout]: {
            feature: OwsOptionsFeatureType.MailLayout,
            useSingleLineMessageListWithRightReadingPane: true,
            animationPreference: 0,
        } as MailLayoutOptions,
        [OwsOptionsFeatureType.Search]: {
            feature: OwsOptionsFeatureType.Search,
            defaultSearchScope: 0,
        } as SearchOptions,
        [OwsOptionsFeatureType.LgpdAds]: {
            feature: OwsOptionsFeatureType.LgpdAds,
            optIn: false,
            optInBit: 0,
        } as LgpdAdsOptions,
        [OwsOptionsFeatureType.MicrosoftChoice]: {
            feature: OwsOptionsFeatureType.MicrosoftChoice,
            optOut: false,
        } as MicrosoftChoiceOptions,
        [OwsOptionsFeatureType.StartUp]: {
            feature: OwsOptionsFeatureType.StartUp,
            defaultReopenItems: 0,
        } as StartUpOptions,
        [OwsOptionsFeatureType.KeyboardShortcuts]: {
            feature: OwsOptionsFeatureType.KeyboardShortcuts,
            keyboardShortcutsMode: MonarchKeyboardShortcutsMode.OutlookForWindows,
        } as KeyboardShortcutsOptions,
        [OwsOptionsFeatureType.EmailHeaderContent]: {
            feature: OwsOptionsFeatureType.EmailHeaderContent,
            inboxShoppingEnabled: true,
        } as EmailHeaderContentOptions,
        [OwsOptionsFeatureType.PinnedPane]: {
            feature: OwsOptionsFeatureType.PinnedPane,
            pinnedPane: PinnablePane.None,
        } as PinnedPaneOptions,
        [OwsOptionsFeatureType.EUEmailAds]: {
            feature: OwsOptionsFeatureType.EUEmailAds,
            optInBit: 0,
            hasSuggestNativeAdsDimissed: false,
        } as EUEmailAdsOptions,
        [OwsOptionsFeatureType.OfficeNeutralTheme]: {
            feature: OwsOptionsFeatureType.OfficeNeutralTheme,
            isSelected: false,
        } as OfficeNeutralThemeOptions,
        [OwsOptionsFeatureType.IsBusinessConsumer]: {
            feature: OwsOptionsFeatureType.IsBusinessConsumer,
            isBusinessConsumer: false,
        } as IsBusinessConsumerOptions,
        [OwsOptionsFeatureType.EditorAutoFormat]: {
            feature: OwsOptionsFeatureType.EditorAutoFormat,
            autoBulletEnabled: true,
            autoFractionsEnabled: true,
            autoHyphenEnabled: true,
            autoNumberingEnabled: true,
            autoOrdinalsEnabled: true,
        } as EditorAutoFormatOptions,
        [OwsOptionsFeatureType.PremiumStatusInPrimarySettings]: {
            feature: OwsOptionsFeatureType.PremiumStatusInPrimarySettings,
            overallPremiumStatusBit: -1,
            premiumCloudCacheAccounts: [],
        } as PremiumStatusInPrimarySettingsOptions,
        [OwsOptionsFeatureType.UseSystemDarkMode]: {
            feature: OwsOptionsFeatureType.UseSystemDarkMode,
            useSystemDarkMode: false,
        } as UseSystemDarkModeOptions,
        [OwsOptionsFeatureType.ComposeFormInPopOut]: {
            feature: OwsOptionsFeatureType.ComposeFormInPopOut,
            composeFormInPopOutEnabled: false,
        } as ComposeFormInPopOutOptions,
        [OwsOptionsFeatureType.TeamsEmbedChat]: {
            feature: OwsOptionsFeatureType.TeamsEmbedChat,
            embedChatLastUsedDate: '2001-01-01T00:00:00.000Z',
        } as TeamsEmbedChatOptions,
        [OwsOptionsFeatureType.AppBarDisplayMode]: {
            feature: OwsOptionsFeatureType.AppBarDisplayMode,
            colorfulIconsEnabled: null,
            labelsEnabled: null,
        } as AppBarDisplayModeOptions,
        [OwsOptionsFeatureType.Dictation]: {
            feature: OwsOptionsFeatureType.Dictation,
            isAutoPunctuationEnabled: null,
            dictationLanguage: null,
        } as DictationOptions,
        [OwsOptionsFeatureType.CopilotChatFREOptions]: {
            feature: OwsOptionsFeatureType.CopilotChatFREOptions,
            shouldCopilotPaneAutoOpen: true,
            copilotFeatureUsageCount: 0,
        } as CopilotChatFREOptions,
        [OwsOptionsFeatureType.MonthlyUpdates]: {
            feature: OwsOptionsFeatureType.MonthlyUpdates,
            monthlyUpdatesEnabled: true,
        } as MonthlyUpdatesOptions,
    };
}
