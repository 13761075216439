import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type TableQuery from './TableQuery';
import type SortBy from './SortBy';
import type MessageSenderScreeningFilter from 'owa-service/lib/contract/MessageSenderScreeningFilter';

export type MailFolderScenarioType =
    | 'mail'
    | 'group'
    | 'category'
    | 'persona'
    | 'privatedistributionlist'
    | 'mailFolderSortBySize'
    | 'searchFolder'
    | 'digestHighPriorityFilter'
    | 'digestNeedsReplyFilter'
    | 'digestNeedsActionFilter';

interface MailFolderTableQuery extends TableQuery {
    categoryName?: string;
    focusedViewFilter: FocusedViewFilter; // enum to indicate if the view is Focused/Other or none
    messageSenderScreeningFilter?: MessageSenderScreeningFilter; // Indicate if the view should be filtered by screened/unscreened senders or not
    requestShapeName: string;
    scenarioType: MailFolderScenarioType;
    sortBy: SortBy;
    viewFilter: ViewFilter; // listview filter values e.g, unread, flagged, toMe;
}

export default MailFolderTableQuery;

export function isDigestFilter(tableQuery: MailFolderTableQuery | undefined): boolean {
    if (!tableQuery) {
        return false;
    }

    return (
        tableQuery.scenarioType === 'digestHighPriorityFilter' ||
        tableQuery.scenarioType === 'digestNeedsReplyFilter' ||
        tableQuery.scenarioType === 'digestNeedsActionFilter'
    );
}
