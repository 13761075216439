import React from 'react';
import { observer } from 'owa-mobx-react';
import { ToggleFavoriteButton } from 'owa-favorites';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isSingleLineListView } from 'owa-mail-layout';
import { getIsSearchTableShown, type TableView } from 'owa-mail-list-store';
import type { UserSettingsStyleSelector } from '../../utils/getStyleSelectorAsPerUserSettings';
import exitCopilotDigestHighPriorityFilter from '../../utils/exitCopilotDigestHighPriorityFilter';
import {
    customTextContainer,
    showSelectionModeButtonSCL,
    showSelectionModeButtonMCL,
    full,
    medium,
    compact,
    customHeaderText,
    customHeaderTextWithFavorite,
    favoriteIconFull,
    favoriteIconMedium,
    favoriteIconCompact,
    backButton,
} from './MailListHeaderSecondRow.scss';
import classnames from 'owa-classnames';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { Button } from '@fluentui/react-components';
import { ArrowLeftRegular } from '@fluentui/react-icons';
import loc from 'owa-localize';
import {
    header_digestHighPriorityFilter,
    header_digestNeedsActionFilter,
    header_digestNeedsReplyFilter,
} from './MailListHeaderSelectionRowContent.locstring.json';

const arrowIcon = <ArrowLeftRegular />;

export interface MailListHeaderSecondRowTextContentProps {
    text?: string;
    containerCssClass?: string;
    showFavoriteToggle?: boolean;
    onFavoriteToggleClick?: (evt: React.MouseEvent<unknown>) => void;
    isInFavorites?: boolean;
    styleSelectorAsPerUserSettings?: UserSettingsStyleSelector;
    mailboxInfo: MailboxInfo;
    isDigestHighPriorityFilter?: boolean;
    isDigestNeedsActionFilter?: boolean;
    isDigestNeedsReplyFilter?: boolean;
    tableView?: TableView;
}

const noop = () => {};

export default observer(function MailListHeaderSecondRowTextContent(
    props: MailListHeaderSecondRowTextContentProps
) {
    const isSingleLineView = isSingleLineListView();
    const showSelectionModeButon = !getIsSearchTableShown();
    const shouldShowFavoriteToggle = props.showFavoriteToggle;

    const isDigestHighPriorityFilter = props.isDigestHighPriorityFilter ?? false;
    const isDigestNeedsActionFilter = props.isDigestNeedsActionFilter ?? false;
    const isDigestNeedsReplyFilter = props.isDigestNeedsReplyFilter ?? false;

    const shouldShowBackButton =
        isDigestHighPriorityFilter || isDigestNeedsActionFilter || isDigestNeedsReplyFilter;

    const onExitHighPriorityFilterClick = React.useCallback(() => {
        if (props.tableView) {
            exitCopilotDigestHighPriorityFilter(props.tableView);
        }
    }, [props.tableView]);

    const displayText = React.useMemo(() => {
        if (isDigestHighPriorityFilter) {
            return loc(header_digestHighPriorityFilter);
        } else if (isDigestNeedsActionFilter) {
            return loc(header_digestNeedsActionFilter);
        } else if (isDigestNeedsReplyFilter) {
            return loc(header_digestNeedsReplyFilter);
        }
        return props.text;
    }, [
        props.text,
        isDigestHighPriorityFilter,
        isDigestNeedsActionFilter,
        isDigestNeedsReplyFilter,
    ]);

    return (
        <div
            className={classnames(
                customTextContainer,
                props.containerCssClass,
                showSelectionModeButon && !isSingleLineView && showSelectionModeButtonSCL,
                showSelectionModeButon && isSingleLineView && showSelectionModeButtonMCL,
                getDensityModeCssClass(full, medium, compact)
            )}
            role="heading"
            aria-level={2}
        >
            {shouldShowBackButton ? (
                <Button
                    icon={arrowIcon}
                    appearance="transparent"
                    size="small"
                    onClick={onExitHighPriorityFilterClick}
                    className={backButton}
                />
            ) : null}
            {displayText && (
                <span
                    className={classnames(
                        customHeaderText,
                        shouldShowFavoriteToggle && customHeaderTextWithFavorite,
                        getDensityModeCssClass(full, medium, compact)
                    )}
                >
                    {displayText}
                </span>
            )}
            {shouldShowFavoriteToggle && !shouldShowBackButton && (
                <ToggleFavoriteButton
                    isInFavorites={props.isInFavorites ?? false}
                    onClick={props.onFavoriteToggleClick ?? noop}
                    iconStyles={getDensityModeCssClass(
                        favoriteIconFull,
                        favoriteIconMedium,
                        favoriteIconCompact
                    )}
                    mailboxInfo={props.mailboxInfo}
                />
            )}
        </div>
    );
},
'MailListHeaderSecondRowTextContent');
