import DOMPurify from 'dompurify';
import type { Config } from 'dompurify';
import { isGulpOrBranching } from 'owa-config';
import { logGreyError } from 'owa-analytics';
import { isBootFeatureEnabled } from 'owa-metatags';
import { trace } from 'owa-trace';

let owaTrustedTypesPolicy: Pick<TrustedTypePolicy, 'createHTML' | 'createScriptURL'> | undefined;
// Regex to match either /mail/sw.js or /mail/<any string>/sw.js or /mail/<any string>/<any string>/sw.js
const regex = /^\/mail\/([^\/]+\/)*sw\.js/;
// Regex to match either /<any string>/<any string>/sw_webpush.js or /sw_<any string>_webpush.js?branch=<any string>
// Includes OWA mini `{prefix}/sw_mini_webpush.js` as defined by router in Microsoft.Owa.Web.Server/Startup.cs
const webpushRegex =
    /^\/([^\/]+\/)*sw(_[^\/_]+)*_webpush\.js(\?(branch|ring|version)=[^\/_=]+){0,1}$/;

if (window.trustedTypes) {
    owaTrustedTypesPolicy = window.trustedTypes.createPolicy('owaTrustedTypesPolicy', {
        createHTML: (html: string, extendedConfig: Config): string => {
            if (html === '') {
                return '';
            }
            const trustedHTML = DOMPurify.sanitize(html, {
                ...extendedConfig,
                RETURN_TRUSTED_TYPE: false,
            });

            return trustedHTML?.toString() ?? '';
        },
        createScriptURL: (url: string): string => {
            if (regex.test(url) || webpushRegex.test(url)) {
                return url;
            }

            // The URL is not meeting the conditions for a safe URL so return empty string
            return '';
        },
    });

    // Create the Trusted Types default policy only on prod builds, on dev mode and PR branches we want to
    // keep throwing the errors with the CSP so that we fix them before merging to master
    if (!isGulpOrBranching() && isBootFeatureEnabled('fwk-enable-default-trusted-types-policy')) {
        window.trustedTypes.createPolicy('default', {
            createHTML: (html: string, type: string, sink: string) => {
                const error = new Error("This document requires 'TrustedHTML' assignment.");
                trace.warn(`${error.message}: ${error.stack}`);
                logGreyError('TrustedTypesHTMLDefaultPolicyError', error, { html, type, sink });
                return html;
            },
            createScriptURL: (url: string, type: string, sink: string) => {
                const error = new Error("This document requires 'TrustedScriptURL' assignment.");
                trace.warn(`${error.message}: ${error.stack}`);
                logGreyError('TrustedTypesScriptURLDefaultPolicyError', error, { url, type, sink });
                return url;
            },
            createScript: (script: string, type: string, sink: string) => {
                const error = new Error("This document requires 'TrustedScript' assignment.");
                trace.warn(`${error.message}: ${error.stack}`);
                logGreyError('TrustedTypesScriptDefaultPolicyError', error, { script, type, sink });
                return script;
            },
        });
    }
}

export { owaTrustedTypesPolicy, regex, webpushRegex };
