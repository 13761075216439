import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "LightningViews" */ './lazyIndex'),
    { name: 'LightningViews' }
);

export const OutlookTeachingBubble = createLazyComponent(lazyModule, m => m.OutlookTeachingBubble);
export const OutlookInlineTipsWithPopover = createLazyComponent(
    lazyModule,
    m => m.OutlookInlineTipsWithPopover
);

export type { OutlookInlineTipsStyle } from './components/OutlookInlineTips';
