import { getListViewMaxWidth } from './getMaxWidths';
import { RECEIVED_COLUMN_WIDTH } from '../internalConstants';
export function getListViewColumnDefaultWidths(): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    // default sender column width is 2x max iconbar width. default width is also min width ;
    const defaultSenderColumnWidth = 192;

    const defaultSubjectColumnWidth =
        getListViewMaxWidth() - defaultSenderColumnWidth - RECEIVED_COLUMN_WIDTH;

    return {
        senderColumnWidth: defaultSenderColumnWidth,
        subjectColumnWidth: defaultSubjectColumnWidth,
        receivedColumnWidth: RECEIVED_COLUMN_WIDTH,
    };
}
