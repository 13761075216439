// extracted by mini-css-extract-plugin
export var backButton = "ZOw1J";
export var clearSelectionButton = "BUcHw";
export var clearSelectionButtonSearch = "SPZ13";
export var compact = "RXDMJ";
export var customHeaderText = "vlQVl";
export var customHeaderTextWithFavorite = "jXaVF";
export var customSelectionContainer = "PhBV4";
export var customSelectionContainerNoSenderImage = "U3hya";
export var customTextContainer = "IG8s8";
export var favoriteIconCompact = "lVYdm";
export var favoriteIconFull = "bi8ao";
export var favoriteIconMedium = "yJGzp";
export var full = "P3G2l";
export var medium = "B7o5q";
export var showSelectionModeButtonMCL = "mCzau";
export var showSelectionModeButtonSCL = "a2Qhm";